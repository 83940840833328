.mask {
  mask-repeat: no-repeat;
  mask-size: contain
}

.mask-13 {
  mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 340 327' fill='none' xmlns='http://www.w3.org/2000/svg' className='absolute md:top-%5B-15%25%5D md:w-%5B55%25%5D lg:top-%5B-20%25%5D lg:w-%5B50%25%5D' %3E%3Cpath d='M320.07 87.2835C274.091 5.05999 137.782 -29.2693 61.6763 31.5573C-14.4297 92.3838 -9.21553 190.622 19.9625 245.27C49.1405 299.918 94.7438 345.337 212.889 318.231C331.034 291.126 366.049 169.507 320.07 87.2835Z' fill='url(%23paint0_linear_2192_20647)' /%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2192_20647' x1='55.4216' y1='25.4637' x2='313.317' y2='338.125' gradientUnits='userSpaceOnUse' %3E%3Cstop stopColor='%23511DEC' /%3E%3Cstop offset='0.0001' stopColor='%23511DEC' /%3E%3Cstop offset='1' stopColor='%238D41ED' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")
}

.mask-12 {
  mask-image: url("data:image/svg+xml,%3Csvg width='621' height='462' viewBox='0 0 621 462' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M491.983 427.051C643.304 362.991 635.088 238.696 598.977 133.523C535.927 -33.7966 370.849 -4.73073 311.62 16.3038C252.391 37.3383 227.553 86.2913 124.571 106.37C21.5888 126.448 -39.742 256.671 29.4223 359.931C98.5866 463.192 340.662 491.11 491.983 427.051Z' fill='%23C4C4C4'/%3E%3C/svg%3E")
}

.mask-10 {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 365 348'%3E%3Cpath fill='url(%23a)' d='M334.937 260.344C401.234 133.903 351.951 20.4 211.73 2.169 71.508-16.063-38.06 82.499 12.568 228.453c50.629 145.955 256.072 158.332 322.369 31.891Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='-73.5' x2='385.756' y1='-61.5' y2='350.977' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%231D41FD'/%3E%3Cstop offset='.404' stop-color='%23501DEC'/%3E%3Cstop offset='1' stop-color='%238D41ED'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}
.mask-11 {
  mask-image: url("data:image/svg+xml,%3Csvg width='182' height='172' viewBox='0 0 182 172' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M159.502 32.3759C118.426 -7.96207 73.4016 -8.05546 32.9702 18.7592C-6.62326 46.2022 -8.92764 100.041 17.887 139.634C44.7016 179.228 104.406 179.018 140.857 157.232C177.308 135.445 200.578 72.714 159.502 32.3759Z' fill='url(%23paint0_linear_1896_10542)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1896_10542' x1='63.9906' y1='-26.2745' x2='134.918' y2='261.042' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23511DEC'/%3E%3Cstop offset='0.0001' stop-color='%23511DEC'/%3E%3Cstop offset='1' stop-color='%238D41ED'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}
.mask-9 {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 305 295'%3E%3Cpath fill='url(%23a)' d='M287.16 78.45C245.894 4.008 123.558-27.072 55.254 27.998c-68.304 55.07-63.625 144.01-37.438 193.486 26.187 49.476 67.116 90.596 173.15 66.056C297 263 328.426 152.891 287.16 78.45Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='363.338' x2='-66.602' y1='294.848' y2='-24.165' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23501DEC'/%3E%3Cstop offset='0' stop-color='%23511DEC'/%3E%3Cstop offset='1' stop-color='%238D41ED'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}
@media(max-width: 767px) {
  .mask-8 {
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 375 240'%3E%3Cpath fill='%230D0B3F' d='M0 0h375v187.093s-60.5 31.797-183.5 46.495C68.5 248.285 0 233.116 0 233.116V0Z'/%3E%3C/svg%3E");
  }
}

.mask-round {
  mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 408 324' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_2170_4214' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='408' height='324'%3E%3Cpath d='M0 15C0 6.71573 6.71573 0 15 0H393C401.284 0 408 6.71573 408 15V323.169H0V15Z' fill='black'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2170_4214)'%3E%3Cpath d='M396.015 -71.7848C279.024 -185.955 150.787 -186.219 35.6305 -110.325C-77.1389 -32.6518 -83.7022 119.73 -7.32927 231.792C69.0437 343.855 239.093 343.262 342.912 281.598C446.732 219.934 513.007 42.3851 396.015 -71.7848Z' fill='url(%23paint0_linear_2170_4214)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2170_4214' x1='458' y1='-163.077' x2='-8.03056' y2='396.398' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F3F5FF'/%3E%3Cstop offset='1' stop-color='%23E7EBFF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}

.mask-bubble {
  mask-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M60.3186 17.0284C51.6506 0.87002 25.9537 -5.8763 11.6062 6.07721C-2.74128 18.0307 -1.7583 37.3363 3.74234 48.0756C9.24298 58.8149 17.8401 67.7405 40.1128 62.4138C62.3855 57.0871 68.9867 33.1868 60.3186 17.0284Z' fill='black'/%3E%3C/svg%3E%0A");
}
